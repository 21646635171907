@import "scrollbar";
@import "colors";
@import "navbar";
@import "config";
@import "animations";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    width: -webkit-fill-available;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    background-color: #e0dfdf;
  }

  html {
    scroll-behavior: smooth;
  }

  /* remove os styles padrões de autofill do browser */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-text-fill-color: #000; /* Altere a cor conforme necessário */
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Remove o ícone de 'mostrar senha' no Edge/IE */
  input[type="password"]::-ms-reveal {
    display: none;
  }

  /* Remove o ícone de 'mostrar senha' no Chrome, caso exista */
  input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}
