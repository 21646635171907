/* Estiliza toda a barra de rolagem (a "trilha") */
::-webkit-scrollbar {
    width: 0.3vw;
    /* Largura da barra de rolagem */
    border-radius: 1vw;
    min-width: 0.5vw;
    max-width: 0.5vw;
    max-height: 0.72vh;
    min-height: 0.72vh;
}

/* Estiliza o fundo da trilha da barra de rolagem */
::-webkit-scrollbar-track {
    background: var(--primary-gray);
    /* Cor de fundo */
    border-radius: 1vw;
}

/* Estiliza o manipulador da barra de rolagem (o "polegar") */
::-webkit-scrollbar-thumb {
    background: var(--primary-blue);
    /* Cor do manipulador */
    border-radius: 1vw;
}

/* Muda a cor do manipulador ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
    background: var(--primary-blue);
    border-radius: 1vw;
}