#slider {
    margin-bottom: 1em;
}

#slider .carousel-caption {
    bottom: 3em;
}

#slider .carousel-caption h5 {
    font-size: 2em;
    text-shadow: #000 1px 1px;
    color: #0e86e9
}

#slider .btn {
    padding: 0.3 0.8em;
    font-size: 1em;
    margin-top: 1em;
}

.carousel-control-prev i,
.carousel-control-next i {
    color: #111;
    font-size: 3em;
}

.carousel-indicators [data-bs-target] {
    background-color: #222;
    opacity: 0.8;
}

.carousel-indicators .active {
    background-color: #000;
}

@media (min-width: 768px) {
    #slider .carousel-caption {
        bottom: 15em;
    }
    #slider .carousel-caption h5 {
        font-size: 3em;
        color: #07487e
    }
    #slider .btn {
        padding: 0.6 1.6em;
        font-size: 1.2em;
    }
    .carousel-indicators {
        bottom: 7em;
    }
}

/* MINI BANNERS */




#mini-banners .card {
    padding: 2em 0.3em;
    border-radius: 0;
}

#mini-banners i {
   font-size:70px;
   color: #0e86e9;
}
 
 @media (min-width: 768px) {
    #mini-banners {
        margin-top: -6em;
    }
}

 /* DESTAQUES */

 #featured .featurette-divider{
    margin-bottom: 3em ;
}

#featured .row {
    text-align: center;
    align-items: center;
}

#featured p {
    font-family: 'Lato', sans-serif;
    
}