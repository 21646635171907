/* Estilo global da navbar */
.navbar {
  top: 0;
  padding: 0;
  height: 8vh;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  overflow: hidden;
  align-items: center;
  align-items: stretch;
  box-shadow: 0px 0px 1px 0px;
  transition: height 0.3s ease;
  justify-content: space-between;
  background: linear-gradient(
    135deg,
    var(--primary-white) 0%,
    var(--primary-white) 75%,
    var(--secondary-blue) 75%,
    var(--secondary-blue) 80%,
    var(--primary-blue) 100%
  );
}

/* NavLink's e componentes */
.navbar-li-group {
  margin: 0;
  gap: 1rem;
}

.navbar img {
  max-height: 7vh;
  transition: max-height 0.5s ease;
}

.navbar-navlink {
  width: 5.5vw;
  display: flex;
  color: inherit;
  color: var(--primary-blue);
  font-weight: bold;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  transition: max-height 0.5s ease;
}

.navbar-navlink:hover {
  border: 0;
  text-decoration: none;
  background-color: var(--primary-blue-transparent-hover);
}

.navbar-navlink.active {
  border-bottom: 3px solid var(--primary-blue);
}

.sign-in-container {
  justify-content: center;
  align-items: center;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  flex-direction: column;
}

.sign-in-container:hover .sign-in {
  background-color: var(--primary-white-transparent-hover);
}

.sign-in-container:hover .sign-in-text {
  color: var(--primary-white-transparent-hover);
}

.sign-in {
  padding: 0.3rem;
  display: flex;
  background-color: var(--primary-white);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: height, width 0.1s ease;
}

.navbar.shrink .sign-in {
  height: 2.5rem;
  width: 2.5rem;
}

.sign-in-text {
  font-weight: bold;
  color: var(--primary-white);
  display: block;
  text-align: center;
  font-size: 0.9rem;
  transition: font-size 0.2s, opacity 0.3s ease;
  opacity: 1;
}

.sign-in svg {
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: middle;
  transition: height 0.3s ease;
}

.navbar-toggler svg {
  width: 3rem;
  height: 3rem;
  transition: height, width 0.5s ease;
}

.navbar.shrink .navbar-toggler svg {
  width: 2.5rem;
  height: 2.5rem;
}

.navbar.shrink img {
  max-height: 3vh;
}

.navbar.shrink .sign-in svg {
  width: 1.3rem;
  height: 1.3rem;
}

.navbar.shrink {
  height: 5vh;
}

/* Estilos para quando a navbar estiver reduzida */
.navbar.shrink .sign-in-text {
  opacity: 0;
  font-size: 0;
}

.navbar.shrink .navbar-logo,
.navbar.shrink .navbar-navlink,
.navbar.shrink .sign-in {
  padding: 0.3rem;
  font-size: 0.8em;
}

/* NAVBAR MOBILE */
/* Estilo global da navbar */
.navbar-mobile {
  position: fixed;
  width: 250px;
  top: 8vh;
  height: calc(100vh - 8vh);
  background-color: #ffffff;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-out, top 0.3s ease-out, height 0.3s ease-out;
  z-index: 999;
}

.navbar-mobile-frame {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(32, 31, 31, 0.73);
  z-index: 998;
}

.navbar.shrink + .navbar-mobile {
  top: 5vh; /* Ajuste para que comece abaixo da navbar fixa reduzida */
  height: calc(
    100vh - 5vh
  ); /* Ajuste para que ocupe o espaço restante da tela */
}

.navbar-mobile.visible {
  transform: translateX(0);
}

.navbar-tab-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.navbar-navlink-mobile {
  padding: 0.3rem;
  width: 100%;
  color: var(--primary-blue);
}

.navbar-group-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.navbar-navlink-mobile:hover {
  border: 0;
  text-decoration: none;
  background-color: var(--primary-blue-transparent-hover);
}

.navbar-navlink-mobile.active {
  border-bottom: 3px solid var(--primary-blue);
  width: auto;
}
